import ng from 'angular';
import acpCore from 'core';

export class AcpDialog {
  private dialogs: {
    [dialogName: string]: ng.IPromise<void>;
  } = {};

  constructor(
    private $ocLazyLoad: any,
    private $nsDialog: any,
    private $q: ng.IQService,
    private nsUtil: nsUtils.NsUtilService,
    private acpCoreDispatcher: any
  ) {
    'ngInject';
  }
  public show(dialog: AcpDialogConfig) {
    if (this.nsUtil.isDefined(dialog.preload)) {
      this.$q
        .when(dialog.preload)
        .then(this.$ocLazyLoad.loadModule)
        .then(() => {
          this.onShow(dialog);
        });
    } else {
      this.onShow(dialog);
    }
  }
  public get(dialogName: string): ng.IPromise<void> | void {
    return this.dialogs[dialogName];
  }

  public hide(dialog: AcpDialogConfig) {
    if (dialog) {
      this.$nsDialog.hide(this.dialogs[dialog.name]);
    } else {
      this.nsUtil.forOwn(this.dialogs, (value) => {
        this.$nsDialog.hide(value);
      });
    }
  }
  public cancel(dialog: AcpDialogConfig) {
    if (dialog) {
      this.$nsDialog.cancel(this.dialogs[dialog.name]).then(() => {
        this.deleteDialog(dialog.name);
      });
    }
  }

  public listen() {
    this.acpCoreDispatcher.dialog.show.onValue((dialog) => this.show(dialog));
    this.acpCoreDispatcher.dialog.hide.onValue((dialog) => this.hide(dialog));
    this.acpCoreDispatcher.dialog.cancel.onValue((dialog) =>
      this.cancel(dialog)
    );
  }
  private deleteDialog(dialogName: string) {
    delete this.dialogs[dialogName];
  }
  private onShow(dialog: AcpDialogConfig) {
    const dialogConfig = dialog;

    if (this.nsUtil.isDefined(dialog.clickOutsideToClose)) {
      dialogConfig.clickOutsideToClose = dialog.clickOutsideToClose;
    } else {
      dialogConfig.clickOutsideToClose = true;
    }
    this.dialogs[dialog.name] = this.$nsDialog.show(dialogConfig);
    this.dialogs[dialog.name].finally(() => this.deleteDialog(dialog.name));
  }
}
export default ng
  .module('acp.shared.services.acp-Dialog', [
    acpCore.name,
    'netspend.legos.dialog'
  ])
  .service('acpDialog', AcpDialog);

export interface AcpDialogConfig {
  preload?: () => Promise<{ default: ng.IModule } | ng.IModule>;
  clickOutsideToClose?: boolean;
  name: string;
}
